import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

class Seo extends Component {
  render() {
    const social = this.props.data.allPrismicSocial.edges[0].node.data

    let socialImage

    if (this.props.image) {
      socialImage = this.props.image.url
    } else if (social.default_image) {
      socialImage = social.default_image.url
    }

    const siteMetadata = {
      title: this.props.title || social.default_title || `This is the default title for Ronik Design`,
      description: this.props.description || social.default_description || `This is the default description for Ronik Design.`,
      image: socialImage || null,
      siteName: social.siteName || `Ronik Design`,
      siteUrl: social.siteUrl || `https://www.ronikdesign.com/`,
      siteLanguage: `en-US`,
      siteLocale: `en_us`,
      authorName: `Ronik`,
      author: `Ronik | https://www.ronikdesign.com`,
    }

    return (
      <Helmet
        htmlAttributes={{
          lang: siteMetadata.siteLanguage,
        }}
        title={siteMetadata.title}
        titleTemplate={`%s | ${siteMetadata.siteName}`}
        meta={[
          {
            name: `description`,
            content: siteMetadata.description,
          },
          {
            property: `og:title`,
            content: siteMetadata.title,
          },
          {
            property: `og:description`,
            content: siteMetadata.description,
          },
          {
            property: `og:image`,
            content: siteMetadata.image,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: siteMetadata.title,
          },
          {
            name: `twitter:description`,
            content: siteMetadata.description,
          },
          {
            name: `viewport`,
            content: `width=device-width, user-scalable=no`,
          },
        ]}
      >

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-Q4KBY152E7"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-Q4KBY152E7');
          `}
        </script>
      </Helmet>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicSocial {
          edges {
            node {
              data {
                site_name
                default_title
                default_description
                default_image {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Seo data={data} {...props} />}
  />
);
